<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
html, body {
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background: black;
  color: white;
}
</style>
